import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../security/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BffApi} from '../../../shared/bff-api';
import {IDisplayColumn} from '../../../shared/library/crud-library/crud-generic-list/crud-generic-list.component';
import {SharedDataService} from '../../../shared/shared-data.service';

@Component({
  selector: 'app-main-admin',
  templateUrl: './main-admin.component.html',
  styleUrls: ['./main-admin.component.scss']
})
export class MainAdminComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute : ActivatedRoute,
    private bffApi: BffApi,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.logout();
  }

  goto2020(): void {
    window.location.href = '/admin/gphg2020/registrations';
    //this.router.navigate(['/admin/gphg2020/registrations']);
  }

  goto2021(): void {
    window.location.href = '/admin/gphg2021/registrations';
    // this.router.navigate(['/admin/gphg2021/registrations']);
  }

  goto2022(): void {
    window.location.href = '/admin/gphg2022/registrations';
  }

  goto2023(): void {
    window.location.href = '/admin/gphg2023/registrations';
  }

  gotoMediation2023(): void {
    window.location.href = '/admin/gphg2023/events';
  }

  gotoMediation2024(): void {
    window.location.href = '/admin/gphg2024/events';
  }

  goto(): void {

  }

}
