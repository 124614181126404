
<div class="row mt-5">
  <div class="col">
    <p class="text-center"><img src="/assets/img/logo_gphg.jpg" width="100" alt="" /></p>
    <div *ngIf="yeartag==='gphg2023'">
      <h4 class="text-center">
        PROGRAMME DE MEDIATION CULTURELLE<br/>
        Exposition des 90 garde-temps présélectionnés 2023<br/>
        26 oct-12 nov - Musée Rath, Genève
      </h4>
      <h2 class="text-center">
        Inscription<br/>
      </h2>
    </div>
    <div *ngIf="yeartag==='gphg2024'">
      <h4 class="text-center">
        PROGRAMME DE MEDIATION CULTURELLE<br/>
        Exposition des 90 garde-temps présélectionnés 2024<br/>
        31 oct-17 nov – Musée Rath, Genève
      </h4>
      <h2 class="text-center">
        Réservation<br/>
      </h2>
    </div>

  </div>
</div>
<div class="row">
  <div class="col">
      <form *ngIf="formstep==''" [formGroup]="form" (ngSubmit)="gotoRecap()">
        <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>
        <div class="row">
          <div class="" style="width: 200px">
            <button class="btn btn-primary" type="submit">S'inscrire / Register</button>
          </div>
            <div *ngIf="yeartag==='gphg2023'" class="col-lg-8 col-sm-12 mt-lg-0 mt-sm-3">
              En vous inscrivant, vous nous autorisez, à utiliser votre adresse email
              pour vous envoyer notre programme de médiation culturelle.
              Vous pouvez vous désabonner à tout moment en nous écrivant à l’adresse <a href="mailto:inscription@gphg.org">inscription@gphg.org</a>
            </div>
            <div *ngIf="yeartag==='gphg2024'" class="col-lg-8 col-sm-12 mt-lg-0 mt-sm-3">
              En vous inscrivant, vous nous autorisez, à utiliser votre adresse email
              pour vous envoyer notre programme de médiation culturelle.
              Vous pouvez vous désabonner à tout moment en nous écrivant à l’adresse <a href="mailto:inscription@gphg.org">inscription@gphg.org</a>
              <br/>
              <span class="text-danger">Les places sont limitées et précieuses.</span> Si vous souhaitez annuler ou modifier votre réservation nous vous remercions de nous contacter
              à l’adresse  <a href="mailto:inscription@gphg.org">inscription@gphg.org</a>
            </div>



        </div>


      </form>
      <div *ngIf="formstep=='recap'">
        <div class="card">
          <div class="card-header">
            Revue de l'inscription / Registration review
          </div>
          <div class="card-body">
            <h5 class="card-title"></h5>
            <app-recap-registration [registrationmodel]="model" [servererror]="servererror"></app-recap-registration>
            <hr />
            <button type="button" class="btn btn-primary me-3" (click)="gotoForm()">Retourner / Back</button>
            <button type="button" class="btn btn-danger" (click)="submit()">Confirmer l'inscription / Confirm registration</button>
          </div>
        </div>
      </div>
      <div *ngIf="formstep=='result'">
        <app-registration-result [successmessage]="successmessage" [errormessage]="errormessage"></app-registration-result>
        <div class="text-center pt-5">
          <a class="mouse-pointer" (click)="reinitForm()">Retourner vers le formulaire d'inscription / Back to the registration form</a>
        </div>
      </div>
  </div>
</div>
<!--<div class="row">-->
<!--  <div class="col mt-5">-->
<!--    <pre>{{ options | json }}</pre>-->
<!--  </div>-->
<!--</div>-->
<div *ngIf="showDebug===true" class="row">
  <div class="col mt-5">
    <pre>{{ model | json }}</pre>
  </div>
</div>



