<h2>Evénement</h2>

<button class="btn btn-light mb-4 mt-2" (click)="back()">Retourner à la liste des événements</button>

<h3>{{date}}</h3>

<h4>{{activityLabel}}</h4>

<ng-template #valueTemplate
             let-valueinput="value"
             let-elementinput="element"
             let-columninput="column">

  <ng-container *ngIf="columninput['columnName']==='createddate'">
    <div [ngClass]="{'text-danger': elementinput['cancelled'] == true }" >
      <span >{{ valueinput }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='userinfo'">
    <div [ngClass]="{'text-danger': elementinput['cancelled'] == true }" >
      <p class="mt-3">
        <strong>{{ valueinput['firstname'] }} {{ valueinput['name'] }}</strong><br/>
        {{ valueinput['function'] }}
      </p>
      <p>
        {{ valueinput['company'] }}<br/>
        <em>Téléphone:</em> {{ valueinput['phone'] }}<br/>
        <em>e-mail:</em> {{ valueinput['email'] }}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="columninput['columnName']==='seats'">
    <div [ngClass]="{'text-danger': elementinput['cancelled'] == true }" >
      <ng-container *ngIf="valueinput != 0 " >
        <h4 >{{valueinput}} <span class="mat-small">pl.</span></h4>
      </ng-container>
    </div>
  </ng-container>

</ng-template>

<app-crud-generic-list [observabledata]="getObservableData()"
                       [displayedColumns]="displayedcolumns"
                       [postTreatmentCallback]="getPostTreatmentCallback()"
                       [idColumnName]="'id'"
                       [entityroot]="entityroot"
                       [specificColumnsTemplates]="[
                        {key: 'createddate', template:valueTemplate},
                        {key: 'userinfo', template:valueTemplate},
                        {key: 'seats', template:valueTemplate},
                       ]"
>
  <ng-template appToolGrid let-id="idvalue" let-parentid="{{eventid}}">
  <app-registration-ellipsis
    [eventid]="eventid"
    [entityroot]="entityroot"
    [idvaluechild]="id">
  </app-registration-ellipsis>
</ng-template>
</app-crud-generic-list>






