
<header>
  <nav class="navbar navbar-light bg-light border-bottom">
    <div class="d-flex justify-content-between w-100">
      <div style="width: 150px;" class="text-center">
        <a [routerLink]="['/admin']">
          <img src="/assets/img/logo_gphg.jpg" width="100" alt="">
        </a>

      </div>
      <div>
      <div class="h6 small col mt-5 text-uppercase">
        Gestion des inscriptions
      </div>
      </div>
      <div style="width:200px" class="mt-4">
        <a class="btn btn-outline-secondary" (click)="logout()">
          Se déconnecter <i class="fas fa-sign-out-alt fa-lg"></i>
        </a>
      </div>
    </div>

  </nav>
</header>
<!--Main Layout-->
<main>
  <div class="container-fluid" >
    <div class="row">
      <div style="width: 150px;" class="bg-light">
        <div class="pl-4 pt-3 pb-3 text-uppercase">Médiation</div>
        <ul class="list-group">
          <a
            [routerLink]="['/admin/atelier']"
              [routerLinkActive]="['active']"
             class="list-group-item list-group-item-action mouse-pointer">
            Médiation
          </a>
          <a
            [routerLink]="['/admin/gphg2023/events']"
            [routerLinkActive]="['active']"
            (click)="gotoMediation2023()"
             class="list-group-item list-group-item-action mouse-pointer">
            Evénements médiation 2023
          </a>
          <a
            [routerLink]="['/admin/gphg2024/events']"
            [routerLinkActive]="['active']"
            (click)="gotoMediation2024()"
            class="list-group-item list-group-item-action mouse-pointer">
            Evénements médiation 2024
          </a>
        </ul>
        <div class="pl-4 pt-3 pb-3 text-uppercase">Evénements</div>
        <ul class="list-group">
          <a [routerLink]="['/admin/gphg2023/registrations']" (click)="goto2023()" [routerLinkActive]="['active']"
             class="list-group-item list-group-item-action">
            GPHG 2023
          </a>
          <a [routerLink]="['/admin/gphg2022/registrations']" (click)="goto2022()" [routerLinkActive]="['active']"
             class="list-group-item list-group-item-action">
            GPHG 2022
          </a>
          <a [routerLink]="['/admin/gphg2021/registrations']" (click)="goto2021()" [routerLinkActive]="['active']"
             class="list-group-item list-group-item-action">
            GPHG 2021
          </a>
          <a [routerLink]="['/admin/gphg2020/registrations']" (click)="goto2020()" [routerLinkActive]="['active']"
             class="list-group-item list-group-item-action">
            GPHG 2020
          </a>
        </ul>
      </div>
      <div class="col border-left p-4" style="min-height:1000px;">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col"></div>
      <div class="col-md-12">
        <hr />
        <h6><small>© FONDATION DU GRAND PRIX D'HORLOGERIE DE GENÈVE</small></h6>
      </div>
      <div class="col"></div>
    </div>

  </div>
</main>


