<h2>Dashboard</h2>

<div class="card text-white bg-primary mb-3" style="max-width: 24rem;">
  <div class="card-header">GPHG 2023</div>
  <div class="card-body">
    <h5 class="card-title">
      {{stats2023.registrationnumber}} inscriptions
      <br />{{stats2023.peopelnumber}} personnes au total</h5>
    <a [routerLink]="['/admin/gphg2023/registrations']" class="btn btn-light">Liste des inscrits 2023</a>
  </div>
</div>

<div class="card text-white bg-primary mb-3" style="max-width: 24rem;">
  <div class="card-header">GPHG 2022</div>
  <div class="card-body">
    <h5 class="card-title">
      {{stats2022.registrationnumber}} inscriptions
      <br />{{stats2022.peopelnumber}} personnes au total</h5>
    <a [routerLink]="['/admin/gphg2022/registrations']" class="btn btn-light">Liste des inscrits 2022</a>
  </div>
</div>

<div class="card text-white bg-primary mb-3" style="max-width: 24rem;">
  <div class="card-header">GPHG 2021</div>
  <div class="card-body">
    <h5 class="card-title">
      {{stats2021.registrationnumber}} inscriptions
      <br />{{stats2021.peopelnumber}} personnes au total</h5>
    <a [routerLink]="['/admin/gphg2021/registrations']" class="btn btn-light">Liste des inscrits 2021</a>
  </div>
</div>

<div class="card text-white bg-primary mb-3" style="max-width: 24rem;">
  <div class="card-header">GPHG 2020</div>
  <div class="card-body">
    <h5 class="card-title">
      {{stats.registrationnumber}} inscriptions
      <br />{{stats.peopelnumber}} personnes au total</h5>
    <a [routerLink]="['/admin/gphg2020/registrations']" class="btn btn-light">Liste des inscrits 2020</a>
  </div>
</div>
