import { Component, OnInit } from '@angular/core';
import {BffApi} from '../../../shared/bff-api';
import {EventStats} from '../../../shared/shared-interface';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent implements OnInit {

  stats: EventStats;
  stats2023: EventStats;
  stats2022: EventStats;
  stats2021: EventStats;

  constructor(
    private bffApi: BffApi
  ) {
    this.stats2023 = {
      registrationnumber: 0,
      peopelnumber: 0
    };
    this.stats2022 = {
      registrationnumber: 0,
      peopelnumber: 0
    };
    this.stats2021 = {
      registrationnumber: 0,
      peopelnumber: 0
    };
    this.stats = {
      registrationnumber: 0,
      peopelnumber: 0
    };
  }

  ngOnInit(): void {
    this.bffApi.getEventStats('gphg2023').subscribe((stats) => {
      this.stats2023 = stats.results;
    });

    this.bffApi.getEventStats('gphg2022').subscribe((stats) => {
      this.stats2022 = stats.results;
    });

    this.bffApi.getEventStats('gphg2021').subscribe((stats) => {
      this.stats2021 = stats.results;
    });

    this.bffApi.getEventStats('gphg2020').subscribe((stats) => {
      this.stats = stats.results;
    });

  }

}
