import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {SharedDataService} from "../../shared/shared-data.service";

@Component({
  selector: 'app-gphg-page',
  templateUrl: './gphg-page.component.html',
  styleUrls: ['./gphg-page.component.scss']
})
export class GphgPageComponent implements OnInit {

  imageLink: string;

  constructor(
    private route: ActivatedRoute,
    private sharedData: SharedDataService
  ) {
    this.imageLink = '';
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      switch (params.get('gphgyear')) {
        case '2021':
          this.imageLink = "assets/img/inscription_site_internet2021.jpg";
          break;
        case '2022':
          this.imageLink = "assets/img/inscription_site_internet2022.jpg";
          break;
        case '2023':
          this.imageLink = "assets/img/inscription_site_internet2023.jpg";
          break;
        default:
          this.imageLink = '';
      }
    });
  }

}
